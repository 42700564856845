import { useState } from 'react';
import './App.css';

function QuestionPrompt({ question }) {
  return(
    <div>
      <p>{question.prompt}</p>
    </div>
  )
}


function Choice({ currentQuestionNumber, questionNumber, choice, selectedAnswer, setSelectedAnswer, isAnswerSubmitted, checked, setChecked }) {
  const [isCrossedout,setIsCrossedOut] = useState(false)
  const isChecked = choice.id === checked

//Handles the various situations regarding clicking an answer choice 
  function handleClick(event) {
    if (isCrossedout) {
      setIsCrossedOut(false)
    }

    if (checked === null) {
      setChecked(event.target.value)
      setSelectedAnswer(event.target.value)
    }
    else {
      if (isChecked) {
        setChecked(null)
        setSelectedAnswer(null)
      }
      else if (!isChecked) {
        setChecked(event.target.value)
        setSelectedAnswer(event.target.value)
      }
    }
  }


  function toggleCrossOut() {
    if (isChecked && !isCrossedout) {
      setChecked(null)
      setSelectedAnswer(null)
    }
    setIsCrossedOut(isCrossedout => !isCrossedout)

  }

  //This is where the magic lies
  const choiceStyle = {
    /* If allowing multiple attempts instead, check if choice.id is in selectedAnswers array rather than " selectedAnswer === choice.id" */
    color: isAnswerSubmitted && (selectedAnswer === choice.id ? (choice.id === questions[currentQuestionNumber].answer ? 'green' : 'red') : 'black') 
  }


  return (
    <div>
{/*       <label style={{display:"inline-block", textAlign:"left", width:"60%", border:"solid skyblue 2px", textDecoration: isCrossedout && "line-through"}}>
 */}      
{/* Cross out entire length of answer choice rather than just the text */}
      <label
        style={{
          display: "inline-block",
          textAlign: "left",
          width: "60%",
          border: "solid skyblue 2px",
          textDecoration: isCrossedout ? "line-through" : "none",
          position: "relative",
        }}
      >
        <input type="radio" name={`q${questionNumber}`} value={choice.id} checked={isChecked} onClick={handleClick} disabled={isAnswerSubmitted} />


          <span style={choiceStyle}>{choice.text}</span>
{/* More logic for Crossing out entire length of answer choice rather than just the text */}
          {isCrossedout && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
              right: 0,
              borderBottom: "1px solid black",
              transform: "translateY(-50%)",
            }}
          />
        )}

      </label>
      <button onClick={toggleCrossOut} style={{display:isAnswerSubmitted && "none"}}>{ isCrossedout ? "Undo" : "Cross-out"}</button>
    </div>

  )
}


function Choices({ question, currentQuestionNumber, questionNumber, selectedAnswer, setSelectedAnswer, isAnswerSubmitted, setIsAnswerSubmitted }) {
  const [checked,setChecked] = useState(null)

  const choicesArray = [] 
  question.choices.forEach((choice)=>{
    choicesArray.push(<Choice key={choice.id} currentQuestionNumber={currentQuestionNumber} questionNumber={questionNumber} choice={choice} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} isAnswerSubmitted={isAnswerSubmitted} checked={checked} setChecked={setChecked} />)
  })
  return(
    <div>
      {choicesArray}
    </div>
  )
}

function QuestionInterface({ currentQuestionNumber, questionNumber, selectedAnswer, setSelectedAnswer, isAnswerSubmitted, setIsAnswerSubmitted, handlePrevious, handleNext }) {






  function handleCheck() {
    setIsAnswerSubmitted(true)
  }
  

  

  return(
    <div className='container'>
        <QuestionPrompt question={questions[currentQuestionNumber]}/>
        <Choices
          question={questions[currentQuestionNumber]}
          currentQuestionNumber={currentQuestionNumber}
          questionNumber={questionNumber}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          isAnswerSubmitted={isAnswerSubmitted}
          setIsAnswerSubmitted={setIsAnswerSubmitted}

        />
        <br/>
        <div className="button" style={{display:"flex", justifyContent:"center"}}>
          <button onClick={handlePrevious} style={{display:currentQuestionNumber <= 0 && "none"}}>Previous</button>


          {!isAnswerSubmitted ? <button onClick={handleCheck} disabled={selectedAnswer === null} style={{display:currentQuestionNumber >= questions.length-1 && "none"}}>Check</button> : <button onClick={handleNext} style={{display:currentQuestionNumber >= questions.length-1 && "none"}}>Next</button> }

        {!isAnswerSubmitted ? <button onClick={handleCheck} disabled={selectedAnswer === null} style={{display:currentQuestionNumber < questions.length-1 && "none"}}>Check</button> : <button onClick={handleNext} style={{display:currentQuestionNumber < questions.length-1 && "none"}}>See Results!</button>}
        </div>
        {/* If allowing multiple attempts, instead of using selectedAnswer, use selectedAnswers[selectedAnswers.length-1] */}
        <div style={{display:(!isAnswerSubmitted) && "none",color:selectedAnswer === questions[currentQuestionNumber].answer ? "green":"red", textAlign: "center"}}>{selectedAnswer === questions[currentQuestionNumber].answer ? "Correct!":"Incorrect"}</div>
        <br/>
        <div style={{display:(!isAnswerSubmitted) && "none"}}><Explanations currentQuestionNumber={currentQuestionNumber} /></div>
    </div>

  )
}

function QuestionSet() {
  const [currentQuestionNumber,setCurrentQuestionNumber] = useState(0)
  const [selectedAnswers,setSelectedAnswers] = useState(Array(questions.length).fill(null))
  const [isAnswerSubmittedArray,setIsAnswerSubmittedArray] = useState(Array(questions.length).fill(false))



  const questionArray = []
  for (let i=0;i<questions.length;i++) {
    questionArray.push(<div style={{display: currentQuestionNumber !== i && "none"}}><QuestionInterface 
    currentQuestionNumber={currentQuestionNumber}
    questionNumber={i}
    selectedAnswer={selectedAnswers[currentQuestionNumber]}
    setSelectedAnswer={(answer)=> {
      const newSelectedAnswers = [...selectedAnswers]
      newSelectedAnswers[currentQuestionNumber] = answer
      setSelectedAnswers(newSelectedAnswers)
    }}
    isAnswerSubmitted={isAnswerSubmittedArray[currentQuestionNumber]}
    setIsAnswerSubmitted={(isSubmittedValue)=> {
      const newIsAnswerSubmittedArray = [...isAnswerSubmittedArray]
      newIsAnswerSubmittedArray[currentQuestionNumber] = isSubmittedValue
      setIsAnswerSubmittedArray(newIsAnswerSubmittedArray)
    }}
    handlePrevious={handlePrevious}
    handleNext={handleNext}
  /></div>)
  }



  function handlePrevious() {
    setCurrentQuestionNumber((currentQuestionNumber)=>currentQuestionNumber-1)
  }

  function handleNext() {
    setCurrentQuestionNumber((currentQuestionNumber)=>currentQuestionNumber+1)
  }

  return (
    
    <div style={{width:"50%"}}>
      {currentQuestionNumber < questions.length && questionArray}
      {currentQuestionNumber >= questions.length && <ResultsPage selectedAnswers={selectedAnswers} />}
      
    </div>
  )
}

function ResultsPage({ selectedAnswers }) {
  const resultsList = []
  for (let i=0;i<questions.length;i++) {
    if (selectedAnswers[i] === null) {
      resultsList.push(<li>Your answer: Omitted. You are <span style={{color:"red"}}>Incorrect</span></li>)
    }
    else {
      resultsList.push(<li>Your answer: {selectedAnswers[i].toUpperCase()}. You are <span style={{color:selectedAnswers[i] === questions[i].answer ? "green":"red"}}>{selectedAnswers[i] === questions[i].answer ? "Correct!":"Incorrect"}</span></li>)
    }
  }
  return (
    <div>
      <h1>Your Results:</h1>
      <ol>{resultsList}</ol>
    </div>
  )
  
}

function Explanations({ currentQuestionNumber }) {
  return (
    <div className="explanations">
      {questions[currentQuestionNumber].explanation.map((choiceExplanation)=><p>{choiceExplanation}</p>)}
    </div>
  )
}


function App() {
  return (
    <div className='app'>
      <QuestionSet />
    </div>
  ) 
}




const questions = [
  {
    prompt: "What is the capital of France?",
    choices: [
      { id: "a", text: "London" },
      { id: "b", text: "Paris" },
      { id: "c", text: "New York" },
      { id: "d", text: "Tokyo" },
    ],
    answer: "b",
    explanation: [
    "Choice A is incorrect. London is the capital of England.",
    "Choice B is correct. Paris is the capital of France.",
    "Choice C is incorrect. New York is located in the U.S.",
    "Choice D is incorrect. Tokyo is the capital of Japan."
  ]
  },
  {
    prompt: "What is the largest planet in our solar system?",
    choices: [
      { id: "a", text: "Mars" },
      { id: "b", text: "Jupiter" },
      { id: "c", text: "Saturn" },
      { id: "d", text: "Earth" },
    ],
    answer: "b",
    explanation: ["Choice B is correct. Jupiter is the largest planet in our solar system."]
  },
  {
    prompt: "What is the square root of 9?",
    choices: [
      { id: "a", text: "0" },
      { id: "b", text: "1" },
      { id: "c", text: "3" },
      { id: "d", text: "81" },
    ],
    answer: "c",
    explanation: ["Choice C is correct. The square root of 9 is 3 because 3x3=9"]
  }
  // add more questions here
]



export default App;
